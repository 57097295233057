<template>
  <div class="d-flex justify-space-between items-center">
    <ion-card class="ml-4 mt-4 w45 py-1 card-invoice-account" @click="onTopUpCredit">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-row>
              <ion-col>
                <ion-text class="text-primary">{{ $t('available_credits') }}</ion-text>
              </ion-col>
              <ion-col size="auto" v-if="isSalesUser && showTopUpCredit">
                <ion-icon
                  :icon="addCircleOutline"
                  class="text-primary fs-4"
                  style="position: absolute; right: 0.2rem"
                ></ion-icon>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-text class="pl-1 fw-bold text-primary">{{
                priceFormatter(currencySymbol, availableCreditAmount)
              }}</ion-text>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
    <ion-card class="mr-4 mt-4 w45 py-1 card-invoice-account">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-row>
              <ion-col>
                <ion-text color="danger">{{
                  $t('unpaid_invoices', { unpaidInvoiceAmount: totalUnpaidInvoices })
                }}</ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-text color="dark" class="pl-1 fw-bold">{{
                priceFormatter(currencySymbol, unpaidAmount)
              }}</ion-text>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</template>
<script>
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import { priceFormatter } from '@/utils/';
import { addCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'CompBannerInvoices',
  emits: ['onTopUpCreditToggle'],
  props: {
    totalUnpaidInvoices: {
      type: Number,
      default: 0
    },
    availableCreditAmount: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    unpaidAmount: {
      type: Number,
      default: 0
    },
    isSalesUser: {
      type: Boolean,
      default: false
    },
    showTopUpCredit: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    return {
      priceFormatter,
      addCircleOutline,
      onTopUpCredit: () => {
        if (!props.isSalesUser) return;
        store.dispatch(`sale/payment/${ACTIONS.SET_ENTRY_TOP_UP_CREDIT}`, `INVOICES_PAGE`);
        emit('onTopUpCreditToggle');
      }
    };
  }
});
</script>
<style lang="scss" scoped>
.card-invoice-account {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}

.w45 {
  width: 45%;
}
</style>
